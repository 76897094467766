export const languages = [
  { id: 1, lang: "en", name: "English", url: "/" },
  { id: 2, lang: "es", name: "Spanish", url: "/es/" },
];

export const defaultLang = "en";

export const showDefaultLang = false;

export const ui = {
  en: {
    en: "English",
    es: "Spanish",
    web: {
      title: "We're building something great",
      span: "Stay tuned!",
    },
    bannerSection: {
      counter: " users on the waiting list",
      description:
        "We aim to elevate emerging artists by enhancing their visibility and unlocking new opportunities. Join the waiting list to create your press kit and connect with key industry players.",
      span: "ALL-IN-ONE PLATFORM FOR ARTISTS AND THE INDUSTRY",
      title: "Boost your popularity",
    },
    benefitsSection: {
      firstScroll: {
        description:
          "Create your profile in just a few steps: upload photos and videos, link your social media and write your biography. Forget about dealing with design programs and outdated PDFs.",
        title: "Easy to create",
      },
      secondScroll: {
        description:
          "Promoters, agencies, and other industry professionals will be able to find you, get interested in your content, and reach out to you to create professional opportunities.",
        title: "Easy to connect",
      },
      thirdScroll: {
        description:
          "Use our MavelTree link-in-bio tool to keep all your social profiles in one link that stays updated. Make your online presence easily accessible and boost engagement with a streamlined profile!",
        title: "Easy to share",
      },
      title: "Your press kit is now",
      titleColor: "your MavelPoint",
    },
    callToAction: {
      button: "Join",
      buttonSuccess: "Joined!",
      error: {
        "500":
          "We couldn't add you to the waiting list. Please try again later.",
        invalidEmail:
          "Invalid email. Please check the format (e.g., name@example.com)",
      },
      input: { placeHolder: "Enter your email" },
      success: "You are already on our waiting list. We will contact you soon.",
    },
    cookie: {
      button: "Back to home page",
      changes: {
        description:
          "We reserve the right to update this cookie policy at any time. We will notify you of any changes by posting the new policy on our website.",
        titleBold: "5. Changes to the Cookie Policy:",
      },
      contact: {
        description:
          "If you have any questions about this cookie policy, you can contact us at privacy@mavelpoint.com",
        titleBold: "6. Contact:",
      },
      how: {
        bulletPoint1: "Remember your preferences and settings.",
        bulletPoint2:
          "Improve the functionality and performance of our website.",
        bulletPoint3: "Analyze traffic and user behavior on our site.",
        bulletPoint4: "Provide personalized content and advertising.",
        description: "We use cookies to:",
        titleBold: "2. How do we use cookies?",
      },
      managing: {
        description:
          "You can manage cookies through your browser settings. Most browsers allow you to refuse or accept cookies, as well as delete cookies already stored on your device. Please note that if you choose to block or delete cookies, some parts of our website may not function properly.",
        titleBold: "4. Managing cookies:",
      },
      subtitle:
        "At MavelPoint, we use cookies to enhance the experience of our users on our website. Below, we explain what cookies are, how we use them, and how you can manage them.",
      title: "Cookie Policy",
      types: {
        bulletPoint1:
          "Essential cookies: These cookies are necessary for the operation of the website and cannot be disabled in our systems. They are usually set in response to actions made by you, such as setting your privacy preferences, logging in, or filling out forms.",
        bulletPoint2:
          "Performance cookies: These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site.",
        bulletPoint3:
          "Functionality cookies: These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages.",
        bulletPoint4:
          "Advertising cookies: These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant ads on other sites.",
        titleBold: "3. Types of cookies we use:",
      },
      what: {
        description:
          "Cookies are small text files that are stored on your device when you visit a website. These files allow the website to remember your actions and preferences (such as login, language, font size, and other display preferences) over a period of time, so you don’t have to re-enter them whenever you return to the site or browse from one page to another.",
        titleBold: "1. What are cookies?",
      },
    },
    earlyAccessBanner: {
      subtitle: "Experience our platform first and be part of the launch",
      title: "Request your",
      titleColor: "early access",
    },
    footer: {
      cookie: "Cookie Policy",
      privacy: "Privacy Policy",
      rights: "© MavelPoint 2024, All Rights Reserved.",
      terms: "Terms and Conditions",
    },
    industryConnectionSection: {
      roles: {
        subtitle:
          "Gain visibility with key players and attract opportunities for professional growth.",
        title: "with the industry",
        titleColor: "Connect",
      },
      withOutRoles: {
        subtitle:
          "Keep your profile up to date automatically, track your metrics, and schedule posts.",
        title: "your social media",
        titleColor: "Plug in",
      },
    },
    marqueeSection: {
      artist: "Artist",
      booker: "Booker",
      bookingAgency: "Booking agency",
      composer: "Composer",
      dj: "Dj",
      filmmaker: "Filmmaker",
      label: "Label",
      manager: "Manager",
      musician: "Musician",
      producer: "Producer",
      promoter: "Promoter",
      singer: "Singer",
      soundTechnician: "Sound technician",
      tourManager: "Tour manager",
    },
    navBar: {
      button: "Join",
      dropDown: {
        artist: "Artist",
        badge: "Coming Soon",
        bookingAgency: "Booking agency",
        label: "Label",
        mediaPress: "Media & Press",
        promoter: "Promoter",
      },
    },
    privacy: {
      button: "Back to home page",
      changes: {
        description:
          "We reserve the right to update this privacy policy at any time. We will notify you of any changes by posting the new policy on our website.",
        titleBold: "6. Changes to the Privacy Policy:",
      },
      collection: {
        description:
          "We collect personal information that you provide to us directly, such as your name, email address, and phone number, when you register on our website, make a purchase, or contact us.",
        titleBold: "1. Information Collection:",
      },
      contact: {
        description:
          "If you have any questions about this privacy policy, you can contact us at privacy@mavelpoint.com",
        titleBold: "7. Contact:",
      },
      protection: {
        description:
          "We implement appropriate security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.",
        titleBold: "3. Information Protection:",
      },
      rights: {
        description:
          "You have the right to access, rectify, and delete your personal data. You may also exercise other rights guaranteed by European and Spanish regulations. For more information, you can contact us at privacy@mavelpoint.com",
        titleBold: "5. User Rights:",
      },
      sharing: {
        bulletPoint1: "With your consent.",
        bulletPoint2: "To comply with legal obligations.",
        bulletPoint3: "To protect our rights and the safety of our users.",
        description:
          "We do not share your personal information with third parties, except in the following circumstances:",
        titleBold: "4. Information Sharing:",
      },
      subtitle:
        "At MavelPoint, we are committed to protecting the privacy of our users. This privacy policy describes how we collect, use, and protect your personal information.",
      title: "Privacy Policy",
      use: {
        bulletPoint1: "Provide and improve our services.",
        bulletPoint2: "Process your transactions and send you confirmations.",
        bulletPoint3: "Respond to your inquiries and provide customer support.",
        bulletPoint4:
          "Send you promotional and marketing communications, if you have consented to receive them.",
        description: "We use your personal information to:",
        titleBold: "2. Use of Information:",
      },
    },
    roleSection: {
      tab: {
        artist: {
          bulletPoint1: "Create and share your press kit in just a few steps.",
          bulletPoint2: "Connect with agencies, labels, and promoters.",
          bulletPoint3: "Send your releases.",
          bulletPoint4:
            "Track your social media and streaming platform metrics.",
          bulletPoint5: "Schedule automatic posts on your social media.",
          bulletPoint6: "Share and promote your events.",
          title: "Artist",
        },
        bookingAgency: {
          bulletPoint1:
            "Easily manage your roster by associating each artist with a booker.",
          bulletPoint2:
            "Handle requests transparently with a customizable calendar view.",
          bulletPoint3: "Share your artists' press kits simply and quickly.",
          bulletPoint4:
            "Access your artists' social media and streaming platforms metrics.",
          bulletPoint5: "Discover new artists.",
          title: "Booking agency",
        },
        label: {
          bulletPoint1: "Receive releases in a single channel and format.",
          bulletPoint2:
            "Make informed decisions with all the data at your fingertips.",
          bulletPoint3:
            "Check the submission history for each artist and view their feedback.",
          bulletPoint4: "Discover new artists.",
          title: "Label",
        },
        mediaPress: {
          bulletPoint1:
            "Automatically showcase your past and upcoming articles on the profiles of artists, labels, promoters, and events.",
          bulletPoint2:
            "Manage event coverage effortlessly: send and receive requests from promoters.",
          bulletPoint3: "Assign team members to cover each event seamlessly.",
          title: "Media & Press",
        },
        promoter: {
          bulletPoint1:
            "Send requests to booking agencies and manage them centrally.",
          bulletPoint2:
            "Access digital press kits that are always up-to-date with all the content and tools you need to promote your events.",
          bulletPoint3: "Reach out to media and press to cover your events.",
          title: "Promoter",
        },
      },
      title: "The perfect match",
      titleColor: "for every role",
    },
  },
  es: {
    en: "Inglés",
    es: "Español",
    web: {
      title: "Algo grande está en camino",
      span: "¡No te lo pierdas!",
    },
    bannerSection: {
      counter: " usuarios en la lista de espera",
      description:
        "Queremos ayudar a los artistas emergentes a aumentar su visibilidad. Únete a la lista de espera para crear tu press kit y conectarte con los principales actores de la industria.",
      span: "PLATAFORMA TODO-EN-UNO PARA ARTISTAS Y LA INDUSTRIA",
      title: "Eleva tu popularidad",
    },
    benefitsSection: {
      firstScroll: {
        description:
          "Crea tu perfil en pocos pasos: sube fotos y videos, vincula tus redes sociales y escribe tu biografía. Olvídate de lidiar con programas de diseño y PDFs desactualizados.",
        title: "Fácil de crear",
      },
      secondScroll: {
        description:
          "Promotores, agencias y otros profesionales de la industria podrán encontrarte, interesarse en tu contenido y ofrecerte oportunidades profesionales.",
        title: "Fácil de conectar",
      },
      thirdScroll: {
        description:
          "Utiliza la herramienta link-in-bio MavelTree para unificar todos tus perfiles sociales en un solo enlace que se actualiza automáticamente. Comparte tus contenidos de manera eficiente.",
        title: "Fácil de compartir",
      },
      title: "Tu press kit ahora es",
      titleColor: "tu MavelPoint",
    },
    callToAction: {
      button: "Únete",
      buttonSuccess: "¡Listo!",
      error: {
        "500":
          "No pudimos añadirte a la lista de espera. Por favor, inténtalo de nuevo más tarde.",
        invalidEmail:
          "Correo electrónico inválido. Por favor, verifica el formato (ej.: nombre@ejemplo.com)",
      },
      input: { placeHolder: "Introduce tu email" },
      success:
        "Ya estás en nuestra lista de espera. Pronto nos pondremos en contacto contigo.",
    },
    cookie: {
      button: "Volver a la página de inicio",
      changes: {
        description:
          "Nos reservamos el derecho de actualizar esta política de cookies en cualquier momento. Te notificaremos cualquier cambio publicando la nueva política en nuestro sitio web.",
        titleBold: "5. Cambios en la Política de Cookies:",
      },
      contact: {
        description:
          "Si tienes alguna pregunta sobre esta política de cookies, puedes ponerte en contacto con nosotros en privacidad@mavelpoint.com",
        titleBold: "6. Contacto:",
      },
      how: {
        bulletPoint1: "Recordar tus preferencias y configuraciones.",
        bulletPoint2:
          "Mejorar la funcionalidad y el rendimiento de nuestro sitio web.",
        bulletPoint3:
          "Analizar el tráfico y el comportamiento de los usuarios en nuestro sitio.",
        bulletPoint4: "Proporcionar contenido y publicidad personalizados.",
        description: "Utilizamos cookies para:",
        titleBold: "2. ¿Cómo utilizamos las cookies?",
      },
      managing: {
        description:
          "Puedes gestionar las cookies a través de la configuración de tu navegador. La mayoría de los navegadores te permiten rechazar o aceptar cookies, así como eliminar las cookies ya almacenadas en tu dispositivo. Ten en cuenta que si decides bloquear o eliminar cookies, es posible que algunas partes de nuestro sitio web no funcionen correctamente.",
        titleBold: "4. Gestión de cookies:",
      },
      subtitle:
        "En MavelPoint utilizamos cookies para mejorar la experiencia de nuestros usuarios en nuestro sitio web. A continuación, te explicamos qué son las cookies, cómo las utilizamos y cómo puedes gestionarlas.",
      title: "Política de Cookies",
      types: {
        bulletPoint1:
          "Cookies esenciales: Estas cookies son necesarias para el funcionamiento del sitio web y no se pueden desactivar en nuestros sistemas. Se suelen configurar en respuesta a acciones realizadas por ti, como configurar tus preferencias de privacidad, iniciar sesión o completar formularios.",
        bulletPoint2:
          "Cookies de rendimiento: Estas cookies nos permiten contar las visitas y fuentes de tráfico para poder medir y mejorar el rendimiento de nuestro sitio. Nos ayudan a saber qué páginas son las más y las menos populares y a ver cómo se mueven los visitantes por el sitio.",
        bulletPoint3:
          "Cookies de funcionalidad: Estas cookies permiten que el sitio web proporcione una funcionalidad y personalización mejoradas. Pueden ser establecidas por nosotros o por terceros cuyos servicios hemos añadido a nuestras páginas.",
        bulletPoint4:
          "Cookies de publicidad: Estas cookies pueden ser establecidas a través de nuestro sitio por nuestros socios publicitarios. Pueden ser utilizadas por esas empresas para crear un perfil de tus intereses y mostrarte anuncios relevantes en otros sitios.",
        titleBold: "3. Tipos de cookies que utilizamos:",
      },
      what: {
        description:
          "Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas un sitio web. Estos archivos permiten que el sitio web recuerde tus acciones y preferencias (como el inicio de sesión, el idioma, el tamaño de la fuente y otras preferencias de visualización) durante un período de tiempo, para que no tengas que volver a configurarlas cada vez que regreses al sitio o navegues de una página a otra.",
        titleBold: "1. ¿Qué son las cookies?",
      },
    },
    earlyAccessBanner: {
      subtitle:
        "Prueba nuestra plataforma antes que nadie y sé parte del lanzamiento",
      title: "Solicita tu",
      titleColor: "acceso anticipado",
    },
    footer: {
      cookie: "Política de Cookies",
      privacy: "Política de privacidad",
      rights: "© MavelPoint 2024, Todos los derechos reservados.",
      terms: "Términos y condiciones",
    },
    industryConnectionSection: {
      roles: {
        subtitle:
          "Gana visibilidad con los actores clave y atrae oportunidades de crecimiento profesional.",
        title: "con la industria",
        titleColor: "Contacta",
      },
      withOutRoles: {
        subtitle:
          "Para mantener tu perfil actualizado, rastrear tus métricas y programar publicaciones.",
        title: "tus redes sociales",
        titleColor: "Conecta",
      },
    },
    marqueeSection: {
      artist: "Artista",
      booker: "Booker",
      bookingAgency: "Agencia de booking",
      composer: "Compositor",
      dj: "DJ",
      filmmaker: "Filmmaker",
      label: "Sello",
      manager: "Manager",
      musician: "Músico",
      producer: "Productor",
      promoter: "Promotor",
      singer: "Cantante",
      soundTechnician: "Técnico de sonido",
      tourManager: "Tour manager",
    },
    navBar: {
      button: "Únete",
      dropDown: {
        artist: "Artista",
        badge: "Próximamente",
        bookingAgency: "Agencia de booking",
        label: "Sello",
        mediaPress: "Medios y prensa",
        promoter: "Promotor",
      },
    },
    privacy: {
      button: "Volver a la página de inicio",
      changes: {
        description:
          "Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Le notificaremos cualquier cambio publicando la nueva política en nuestro sitio web.",
        titleBold: "6. Cambios en la Política de Privacidad:",
      },
      collection: {
        description:
          "Recopilamos información personal que usted nos proporciona directamente, como su nombre, dirección de correo electrónico y número de teléfono, cuando se registra en nuestro sitio web, realiza una compra o se pone en contacto con nosotros.",
        titleBold: "1. Recopilación de Información:",
      },
      contact: {
        description:
          "Si tiene alguna pregunta sobre esta política de privacidad, puede contactarnos en privacidad@mavelpoint.com",
        titleBold: "7. Contacto:",
      },
      protection: {
        description:
          "Implementamos medidas de seguridad adecuadas para proteger su información personal contra el acceso no autorizado, la alteración, divulgación o destrucción.",
        titleBold: "3. Protección de la Información:",
      },
      rights: {
        description:
          "Usted tiene derecho a acceder, rectificar y suprimir sus datos personales. También podrá ejercer otros derechos garantizados por la normativa europea y española. Para más información, puede ponerse en contacto con nosotros en privacidad@mavelpoint.com",
        titleBold: "5. Derechos del Usuario:",
      },
      sharing: {
        bulletPoint1: "Con su consentimiento.",
        bulletPoint2: "Para cumplir con obligaciones legales.",
        bulletPoint3:
          "Para proteger nuestros derechos y la seguridad de nuestros usuarios.",
        description:
          "No compartimos su información personal con terceros, excepto en las siguientes circunstancias:",
        titleBold: "4. Compartir Información:",
      },
      subtitle:
        "En MavelPoint nos comprometemos a proteger la privacidad de nuestros usuarios. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos su información personal.",
      title: "Política de Privacidad",
      use: {
        bulletPoint1: "Proporcionar y mejorar nuestros servicios.",
        bulletPoint2: "Procesar sus transacciones y enviarle confirmaciones.",
        bulletPoint3:
          "Responder a sus consultas y ofrecerle soporte al cliente.",
        bulletPoint4:
          "Enviarle comunicaciones promocionales y de marketing, si ha dado su consentimiento para ello.",
        description: "Utilizamos su información personal para:",
        titleBold: "2. Uso de la Información:",
      },
    },
    roleSection: {
      tab: {
        artist: {
          bulletPoint1: "Crea y comparte tu press kit en pocos pasos.",
          bulletPoint2: "Conecta con agencias, sellos y promotores.",
          bulletPoint3: "Envía tus releases.",
          bulletPoint4:
            "Monitorea las métricas de tus redes sociales y plataformas de streaming.",
          bulletPoint5:
            "Programa publicaciones automáticas en tus redes sociales.",
          bulletPoint6: "Comparte y promociona tus eventos.",
          title: "Artista",
        },
        bookingAgency: {
          bulletPoint1:
            "Administra tu roster fácilmente asociando cada artista con un booker.",
          bulletPoint2:
            "Gestiona las ofertas de manera transparente con una vista de calendario personalizable.",
          bulletPoint3:
            "Comparte los press kits de tus artistas de manera simple y rápida.",
          bulletPoint4:
            "Accede a las métricas de redes sociales y plataformas de streaming de tus artistas.",
          bulletPoint5: "Descubre nuevos artistas.",
          title: "Agencia de booking",
        },
        label: {
          bulletPoint1: "Recibe releases en un único canal y formato.",
          bulletPoint2: "Toma decisiones con toda la información a tu alcance.",
          bulletPoint3:
            "Consulta el historial de envíos de cada artista y revisa el feedback de cada uno.",
          bulletPoint4: "Descubre nuevos artistas.",
          title: "Sello",
        },
        mediaPress: {
          bulletPoint1:
            "Muestra automáticamente tus artículos pasados y futuros en los perfiles de artistas, sellos, promotores y eventos.",
          bulletPoint2:
            "Gestiona la cobertura de eventos sin esfuerzo: envía y recibe solicitudes de promotores.",
          bulletPoint3:
            "Asigna miembros del equipo para cubrir cada evento sin complicaciones.",
          title: "Medios y Prensa",
        },
        promoter: {
          bulletPoint1:
            "Envía ofertas a las agencias de booking y gestiónalas de forma centralizada.",
          bulletPoint2:
            "Accede a press kits digitales siempre actualizados con todo el contenido y las herramientas que necesitas para promocionar tus eventos.",
          bulletPoint3:
            "Contacta con medios de comunicación para que cubran tus eventos.",
          title: "Promotor",
        },
      },
      title: "La solución perfecta",
      titleColor: "para cada rol",
    },
  },
} as const;
