import { ui, defaultLang, showDefaultLang, languages } from "./ui";

export function getLangFromUrl(url: URL) {
  const [, lang] = url.pathname.split("/");
  if (lang in ui) {
    return lang as keyof typeof ui;
  }
  return defaultLang;
}

export function useTranslations(lang: keyof typeof ui) {
  return function t(path: string) {
    const keys = path.split(".");
    let result = ui[lang];
    for (const key of keys) {
      if (result?.[key] === undefined) {
        result = ui[defaultLang]?.[key];
      } else {
        result = result[key];
      }

      if (result === undefined) {
        console.error(`Translation not found for path: ${path}`);
        return "en";
      }
    }
    return result;
  };
}

export function useTranslatedPath(lang: keyof typeof ui) {
  return function translatePath(path: string, l: string = lang) {
    return !showDefaultLang && l === defaultLang ? path : `/${l}/${path}`;
  };
}

export function getPathAfterLocale(pathname) {
  const pathSegments = pathname.split("/").filter(Boolean);

  const nonDefaultLocales = languages
    .filter((lang) => lang.lang !== defaultLang)
    .map((lang) => lang.lang);

  const firstSegment = pathSegments[0];

  if (nonDefaultLocales.includes(firstSegment)) {
    pathSegments.shift();
  }

  return pathSegments.join("/");
}
