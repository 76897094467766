import { languages } from "@/i18n/ui";
import { useEffect, useState } from "react";

export default function useLangFromUrl(): string {
  const [lang, setLang] = useState("en");

  useEffect(() => {
    const pathLang = window.location.pathname.split("/")[1];

    // Check pathLang in languages array
    const matchedLang = languages.find(
      (language) => language.lang === pathLang,
    );

    if (matchedLang) {
      setLang(matchedLang.lang);
    } else {
      setLang("en");
    }
  }, []);

  return lang;
}
