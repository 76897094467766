import clsx from "clsx";
import { Disclosure } from "@headlessui/react";
import { useTranslations } from "@/i18n/utils";
import { getRelativeLocaleUrl } from "astro:i18n";

import logoNegative from "@/assets/logo/Logotipo_Negativo.png";
import LanguageSelector from "./languageSelector";

import useLangFromUrl from "@/hooks/useLangFromUrl";
import { useCurrentBreakpoint } from "@/hooks/useCurrentBreakpoint";

interface NavBarProps {
  artistName?: string;
  pathname: string;
}

const commonHeder =
  "absolute w-full p-3 md:px-7 md:py-3 top-0 z-50 md:fixed max-w-[1440px]";

const navBarType = {
  default: {
    desktop: "bg-transparent",
    mobile: "bg-white-10 backdrop-blur-3xl",
  },
  auth: {
    desktop:
      "bg-white-10 md:bg-white-10 backdrop-blur-3xl md:backdrop-blur-2xl md:top-8 md:rounded-xl",
    mobile:
      "bg-white-10 md:bg-white-10 backdrop-blur-3xl md:backdrop-blur-2xl md:top-8 md:rounded-xl",
  },
};

interface NavBarProps {
  pathname: string;
  type?: keyof typeof navBarType;
  classNamesHeader?: string;
}

export default function NavBar({
  pathname,
  type = "default",
  classNamesHeader,
}: NavBarProps) {
  const breakpoint = useCurrentBreakpoint();
  const lang = useLangFromUrl() as "en" | "es";
  const t = useTranslations(lang);
  const homeURL = getRelativeLocaleUrl(`${lang}`, "");

  const ismobile = () => {
    if (breakpoint === "xs" || breakpoint === "sm") {
      return "mobile";
    }
    return "desktop";
  };

  const classNameHeader = clsx(
    commonHeder,
    navBarType[type][ismobile()],
    classNamesHeader,
  );

  return (
    <header className={classNameHeader}>
      <Disclosure as="nav" className="relative mx-auto" id="navbar">
        <div className="mx-auto w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <div className="flex">
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-1 text-white-70 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white-70">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <List
                    aria-hidden="true"
                    className="block h-6 w-6 group-data-[open]:hidden"
                  />
                  <X
                    aria-hidden="true"
                    className="hidden h-6 w-6 group-data-[open]:block"
                  />
                </DisclosureButton>
              </div> */}

              <a href={homeURL} className="relative block h-[23px] w-[101px]">
                <img
                  alt="MavelPoint company"
                  src={logoNegative.src}
                  className="object-contain"
                />
              </a>
            </div>
            <div className="flex items-center">
              <LanguageSelector lang={lang} pathname={pathname} />
            </div>
          </div>
        </div>
      </Disclosure>
    </header>
  );
}
