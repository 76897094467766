import { languages } from "@/i18n/ui";
import { getPathAfterLocale, useTranslations } from "@/i18n/utils";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { Check, CaretUp, CaretDown } from "@phosphor-icons/react";

type Props = {
  lang: "en" | "es";
  pathname: string;
};

export default function LanguageSelector({ lang, pathname }: Props) {
  const languageSelected = languages.find((l) => l.lang === lang);
  const t = useTranslations(lang);
  const lastSegmentCurrentPath = getPathAfterLocale(pathname);

  return (
    <Listbox value={languageSelected}>
      {({ open }) => (
        <div className="relative">
          <ListboxButton className="relative w-full cursor-pointer rounded-md bg-transparent py-1.5 pl-3 pr-10 text-left">
            <span className="block truncate text-white-70">
              {languageSelected.lang.toUpperCase()}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {open ? (
                <CaretUp aria-hidden="true" className="h-5 w-5 text-white-70" />
              ) : (
                <CaretDown
                  aria-hidden="true"
                  className="h-5 w-5 text-white-70"
                />
              )}
            </span>
          </ListboxButton>

          <ListboxOptions
            anchor={{ to: "bottom end", gap: "4px" }}
            transition
            className="absolute z-10 mt-2.5 max-h-60 w-[7rem] overflow-auto rounded-md bg-white-5 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {languages.map((item) => (
              <ListboxOption
                key={item.id}
                value={item}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-white-70"
              >
                <a
                  href={`${item.url}${lastSegmentCurrentPath}`}
                  className="block truncate font-normal group-data-[selected]:font-semibold"
                >
                  {String(t(item.lang))}
                </a>
                {item.lang === languageSelected.lang && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <Check aria-hidden="true" className="h-5 w-5" />
                  </span>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      )}
    </Listbox>
  );
}
